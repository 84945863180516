import { render, staticRenderFns } from "./Plugins.vue?vue&type=template&id=94c6e9ea&scoped=true"
import script from "./Plugins.vue?vue&type=script&lang=js"
export * from "./Plugins.vue?vue&type=script&lang=js"
import style0 from "./Plugins.vue?vue&type=style&index=0&id=94c6e9ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94c6e9ea",
  null
  
)

export default component.exports