<template>
  <div class="">
    <v-tabs-items :value="+addPlugins">
      <v-tab-item transition="tab-slide" reverse-transition="tab-slide-reverse">
        <template v-if="!itemsLoading && !items.length && !searchTerm">
          <v-container class="px-0">
            <v-row class="mx-0">
              <v-card class="mt-12 w-100" elevation="4">
                <TableFullScreenMessage
                  maxWidth="410px"
                  :title="$t('message.emptyTable.plugin.title')"
                  :desc="$t('message.emptyTable.plugin.description')"
                >
                  <template v-slot:image>
                    <svg
                      width="65"
                      height="65"
                      viewBox="0 0 65 65"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_7045_263833)">
                        <g clip-path="url(#clip1_7045_263833)">
                          <path
                            d="M3.5 45.3999C-6.2 36.2299 8.89 7.1699 30.27 3.4999C54.28 -0.630098 42.66 21.9499 58.93 30.9499C81.55 43.4599 32.18 72.4199 23.56 64.2999C18.49 59.5299 26.94 53.6699 20.71 47.7999C14.48 41.9399 8.04 49.6499 3.5 45.3799V45.3999Z"
                            fill="#F9F9FB"
                          />
                        </g>
                        <path
                          d="M54.5601 10.4902L50.5601 6.49023"
                          stroke="#07C07E"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                        <path
                          d="M50.5601 10.4902L54.5601 6.49023"
                          stroke="#07C07E"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                        <path
                          d="M6.5 27.0205L2.5 23.0205"
                          stroke="#797997"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                        <path
                          d="M2.5 27.0205L6.5 23.0205"
                          stroke="#797997"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                        <path
                          d="M9.16003 55.6602C10.82 55.6602 12.16 54.3202 12.16 52.6602C12.16 51.0002 10.82 49.6602 9.16003 49.6602C7.50003 49.6602 6.16003 51.0002 6.16003 52.6602C6.16003 54.3202 7.50003 55.6602 9.16003 55.6602Z"
                          stroke="#07C07E"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                        <path
                          d="M54.35 18.1404V26.7604C54.35 27.6304 53.65 28.3304 52.78 28.3304H49.84C47.39 28.3304 45.2 30.1204 44.97 32.5604C44.82 34.1004 45.43 35.5104 46.45 36.4604C46.45 36.3804 46.45 36.3004 46.45 36.2204C46.68 33.7804 48.87 31.9904 51.32 31.9904H54.26C55.13 31.9904 55.83 31.2904 55.83 30.4204V20.1204C55.83 19.1804 55.2 18.3904 54.34 18.1304L54.35 18.1404Z"
                          fill="#EEEEF4"
                        />
                        <path
                          d="M54.35 42.4404V49.8804C54.35 51.0304 53.42 51.9604 52.27 51.9604H42.74C42.77 51.9604 43.65 51.9604 42.74 51.9604V54.0604C42.74 54.9304 43.44 55.6304 44.31 55.6304H53.77C54.92 55.6304 55.85 54.7004 55.85 53.5504V44.0104C55.85 43.1704 55.19 42.4904 54.36 42.4504L54.35 42.4404Z"
                          fill="#EEEEF4"
                        />
                        <path
                          d="M40.25 47.1305C40.25 44.3905 37.9 42.1904 35.1 42.4604C32.66 42.6904 30.87 44.8805 30.87 47.3305V50.4005C30.87 51.2705 30.17 51.9705 29.3 51.9705H18.85C18.64 51.9705 18.45 51.9304 18.26 51.8704V53.5405C18.26 54.6905 19.19 55.6204 20.34 55.6204H30.79C31.66 55.6204 32.36 54.9204 32.36 54.0504V50.9804C32.36 48.5304 34.15 46.3405 36.59 46.1105C38.01 45.9705 39.31 46.4705 40.25 47.3505V47.1204V47.1305Z"
                          fill="#EEEEF4"
                        />
                        <path
                          d="M51.13 41.3005C48.39 41.3005 46.19 38.9505 46.46 36.1505C46.69 33.7105 48.88 31.9205 51.33 31.9205H54.27C55.14 31.9205 55.84 31.2205 55.84 30.3505V20.0505C55.84 18.9005 54.91 17.9705 53.76 17.9705H43.31C42.44 17.9705 41.74 17.2705 41.74 16.4005V14.4205C41.74 9.93047 39.64 8.48047 37.05 8.48047C34.46 8.48047 32.36 9.93047 32.36 14.4205V16.4005C32.36 17.2705 31.66 17.9705 30.79 17.9705H20.34C19.19 17.9705 18.26 18.9005 18.26 20.0505V30.3505C18.26 31.2205 17.56 31.9205 16.69 31.9205H15.39C10.4 31.9205 9.17004 34.0205 9.17004 36.6105C9.17004 39.2005 10.41 41.3005 15.23 41.3005H16.69C17.56 41.3005 18.26 42.0005 18.26 42.8705V53.4705C18.26 54.6205 19.19 55.5505 20.34 55.5505H30.79C31.66 55.5505 32.36 54.8505 32.36 53.9805V50.9105C32.36 48.4605 34.15 46.2705 36.59 46.0405C39.39 45.7705 41.74 47.9705 41.74 50.7105V53.9805C41.74 54.8505 42.44 55.5505 43.31 55.5505H53.76C54.91 55.5505 55.84 54.6205 55.84 53.4705V42.8705C55.84 42.0005 55.14 41.3005 54.27 41.3005H51.13Z"
                          stroke="#B0B0D3"
                          stroke-width="2"
                          stroke-miterlimit="10"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_7045_263833">
                          <rect
                            width="64"
                            height="64"
                            fill="white"
                            transform="translate(0.5 0.0200195)"
                          />
                        </clipPath>
                        <clipPath id="clip1_7045_263833">
                          <rect
                            width="64.32"
                            height="62.71"
                            fill="white"
                            transform="translate(0.5 3)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </template>
                  <template v-slot:action>
                    <v-btn
                      :large="!$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.smAndDown"
                      elevation="0"
                      color="primary"
                      @click="addPlugins = true"
                      class="p-2 ml-0 add-new-button"
                    >
                      <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                        >$plus</v-icon
                      >
                      {{ $vuetify.breakpoint.mobile ? "" : $t('button.addNew') }}
                    </v-btn>
                  </template>
                </TableFullScreenMessage>
              </v-card>
            </v-row>
          </v-container>
        </template>
        <template v-else>
          <v-container class="page-title">
            <v-row>
              <v-col class="page-title__title-box">
                <h4 class="page-title__title">{{ $t('heading.instance.plugin.title') }}</h4>
                <list-grid-switch
                  v-if="!$vuetify.breakpoint.mdAndDown"
                  :listStyle.sync="listStyle"
                ></list-grid-switch>
              </v-col>
              <v-col class="page-title__filter-controls">
                <data-iterator-filter-controls
                  :sortBy="listStyle ? undefined : sortBy"
                  :sortDesc="sortDesc"
                  :keys="headers"
                  :showSearchTerm="true"
                  :searchTerm="searchTerm"
                  :fullWidthSearch="true"
                  @update:sortBy="changeSortBy"
                  @update:sortDesc="handleSortDescChange"
                  @update:searchTerm="handleSearchTermChange"
                ></data-iterator-filter-controls>
                <v-btn
                  :large="!$vuetify.breakpoint.smAndDown"
                  :small="$vuetify.breakpoint.smAndDown"
                  elevation="0"
                  color="primary"
                  class="p-2 add-new-button page-title__add-button"
                  @click="addPlugins = true"
                >
                  <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                    >$plus</v-icon
                  >
                  {{ $vuetify.breakpoint.mobile ? "" : $t('button.addNew') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <template v-if="listStyle === 0 || $vuetify.breakpoint.mdAndDown">
            <data-iterator
              :searchTerm="searchTerm"
              :itemsTotal="itemsTotal"
              :items="itemsLoading ? [] : items"
              :keys="headers"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              :possibleItemsPerPage="[[8, 16, 24]]"
              :itemsPerPage="itemsPerPage"
              :page="page"
              :loading="itemsLoading"
              @clearAllFilters="handleSearchTermChange('')"
              @update:page="changePage"
              @update:itemsPerPage="changePerPage"
            >
              <template v-slot:loading>
                <v-row class="">
                  <v-col
                    v-for="(item, key) in 8"
                    :key="key"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    xl="3"
                    class="responsive-card"
                  >
                    <plugin-card-skeleton />
                  </v-col>
                </v-row>
              </template>

              <template v-slot:grid="{ items: items }">
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  md="6"
                  xl="3"
                  class="responsive-card"
                  v-for="(item, key) in items"
                  :key="key"
                >
                  <plugin-card
                    :item="item"
                    :checked="checkedItems.includes(item.name)"
                    @checked="handleItemCheck(item)"
                    @action-button-activate="showPluginActivateModal"
                    @action-button-deactivate="showPluginDeactivateModal"
                    @action-button-update="showPluginUpdateModal"
                    @action-button-delete="showPluginDeleteModal($event)"
                    @action-button-read-more="readMoreActive = $event"
                  ></plugin-card>
                </v-col>
              </template>
            </data-iterator>
          </template>

          <!-- TABLE VIEW -->

          <template v-if="listStyle === 1 && !$vuetify.breakpoint.mdAndDown">
            <plugin-table
              :checkedItems="checkedItems"
              :headers="headers"
              :items="items"
              :itemsLoading="itemsLoading"
              :itemsPerPage="itemsPerPage"
              :itemsTotal="itemsTotal"
              :page="page"
              :possibleItemsPerPage="[[8, 16, 24]]"
              :searchTerm="searchTerm"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              @update:check-all="handleSelectAll"
              @update:checked-items="handleItemCheck"
              @update:itemsPerPage="changePerPage"
              @update:page="changePage"
              @update:sortBy="changeSortBy"
              @update:sortDesc="changeSortDesc"
              @update:searchTerm="handleSearchTermChange($event)"
              @action-button-activate="showPluginActivateModal"
              @action-button-deactivate="showPluginDeactivateModal"
              @action-button-update="showPluginUpdateModal"
              @action-button-delete="showPluginDeleteModal($event)"
              @action-button-read-more="readMoreActive = $event"
            >
            </plugin-table>
          </template>
        </template>
      </v-tab-item>
      <v-tab-item transition="tab-slide" reverse-transition="tab-slide-reverse">
        <v-container class="page-title">
          <v-row>
            <v-col class="page-title__title-box">
              <v-btn @click="addPlugins = false" icon small class="mr-2">
                <v-icon size="24">$arrowleft</v-icon>
              </v-btn>
              <h4 class="page-title__title">{{ $t('heading.instance.plugin.add.title') }}</h4>
              <list-grid-switch
                v-if="!$vuetify.breakpoint.mdAndDown"
                :listStyle.sync="listStyle"
              ></list-grid-switch>
            </v-col>
            <v-col class="page-title__filter-controls">
              <data-iterator-filter-controls
                :disabled="!newPlugins.searchTerm"
                :showSearchTerm="false"
                :sortBy="!listStyle ? newPlugins.sortBy : undefined"
                :keys="!listStyle ? newPlugins.keys : undefined"
                @update:sortBy="newPlugins.sortBy = $event"
              ></data-iterator-filter-controls>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="px-0 mb-6">
          <v-row class="mx-0">
            <NewItemSearchCard
              :isActive="addPlugins ? true : false"
              :item="newPlugins"
              @update:searchTerm="newPluginsHandleSearchTermChange($event)"
            />
          </v-row>
        </v-container>

        <template v-if="!filteredNewPlugins.length">
          <v-container class="px-0">
            <v-row class="mx-0">
              <v-card
                class="w-100 d-flex align-center"
                style="min-height: 360px"
                elevation="4"
              >
                <TableFullScreenMessage
                  class="my-auto"
                  maxWidth="410px"
                  :title="$t('heading.instance.plugin.add.empty.title')"
                  :desc="$t('heading.instance.plugin.add.empty.text')"
                >
                  <template v-slot:image>
                    <svg
                      width="73"
                      height="65"
                      viewBox="0 0 73 65"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.00306 42.5152C-6.69594 33.3411 8.38886 4.28969 29.7723 0.615481C53.7802 -3.51515 70.6222 14.4679 66.4688 35.2124C61.8817 58.102 31.6893 69.5355 23.0629 61.4111C17.9966 56.6415 26.4404 50.7764 20.2102 44.9114C13.9801 39.0464 7.54448 46.7599 3.00306 42.4924V42.5152Z"
                        fill="#F9F9FB"
                      />
                      <path
                        d="M55.1235 5.01999L51.1235 1.01999"
                        stroke="#07C07E"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M51.1235 5.01999L55.1235 1.01999"
                        stroke="#07C07E"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.12354 39.02L1.12354 35.02"
                        stroke="#07C07E"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M1.12354 39.02L5.12354 35.02"
                        stroke="#07C07E"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M68.1235 17.02C69.7804 17.02 71.1235 15.6768 71.1235 14.02C71.1235 12.3631 69.7804 11.02 68.1235 11.02C66.4667 11.02 65.1235 12.3631 65.1235 14.02C65.1235 15.6768 66.4667 17.02 68.1235 17.02Z"
                        stroke="#07C07E"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M14.7554 13.0453H56.5608C57.9159 13.0453 59 14.2844 59 15.7763V24.02H12V16.105C12 14.4108 13.2422 13.02 14.7554 13.02V13.0453Z"
                        fill="#EEEEF4"
                      />
                      <path
                        d="M56.2446 53.02L14.4392 53.02C13.0841 53.02 12 51.9572 12 50.6774V24.02L59 24.02V50.3738C59 51.827 57.7578 53.02 56.2446 53.02Z"
                        fill="white"
                      />
                      <path
                        d="M57.5781 13.02H14.4219C13.0843 13.02 12 14.0976 12 15.4269V50.6131C12 51.9424 13.0843 53.02 14.4219 53.02H57.5781C58.9157 53.02 60 51.9424 60 50.6131V15.4269C60 14.0976 58.9157 13.02 57.5781 13.02Z"
                        stroke="#B0B0D3"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13 25.02H59"
                        stroke="#B0B0D3"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 21.02C21.1046 21.02 22 20.1246 22 19.02C22 17.9154 21.1046 17.02 20 17.02C18.8954 17.02 18 17.9154 18 19.02C18 20.1246 18.8954 21.02 20 21.02Z"
                        stroke="#797997"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <rect
                        x="18"
                        y="29.02"
                        width="8"
                        height="8"
                        rx="2"
                        stroke="#B0B0D3"
                        stroke-width="2"
                      />
                      <rect
                        x="18"
                        y="41.02"
                        width="8"
                        height="8"
                        rx="2"
                        stroke="#B0B0D3"
                        stroke-width="2"
                      />
                      <rect
                        x="31"
                        y="29.02"
                        width="8"
                        height="8"
                        rx="2"
                        stroke="#B0B0D3"
                        stroke-width="2"
                      />
                      <rect
                        x="31"
                        y="41.02"
                        width="8"
                        height="8"
                        rx="2"
                        stroke="#B0B0D3"
                        stroke-width="2"
                      />
                      <rect
                        x="45"
                        y="29.02"
                        width="8"
                        height="8"
                        rx="2"
                        stroke="#B0B0D3"
                        stroke-width="2"
                      />
                      <rect
                        x="45"
                        y="41.02"
                        width="8"
                        height="8"
                        rx="2"
                        stroke="#B0B0D3"
                        stroke-width="2"
                      />
                      <path
                        d="M46 47.02C49.866 47.02 53 43.886 53 40.02C53 36.154 49.866 33.02 46 33.02C42.134 33.02 39 36.154 39 40.02C39 43.886 42.134 47.02 46 47.02Z"
                        fill="#F9F9FB"
                        fill-opacity="0.6"
                        stroke="#797997"
                        stroke-width="2"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M51 45.02L56 50.02"
                        stroke="#797997"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </template>
                  <template v-slot:action>
                    <v-btn
                      @click="addPlugins = false"
                      outlined
                      large
                      block
                      color="gray lighten-3"
                    >
                      <span class="gray--text text--darken-1">{{ $t('button.back') }}</span>
                    </v-btn>
                  </template>
                </TableFullScreenMessage>
              </v-card>
            </v-row>
          </v-container>
        </template>
        <template v-else>
          <template v-if="$vuetify.breakpoint.mdAndDown || listStyle === 0">
            <data-iterator
              class="py-0"
              :items="filteredNewPlugins"
              :loading="newPlugins.itemsLoading"
              :itemsPerPage="newPlugins.itemsPerPage"
              :itemsTotal="newPlugins.itemsTotal"
              :keys="newPlugins.headers"
              :sortBy="newPlugins.sortBy"
              :sortDesc="true"
              :possibleItemsPerPage="[[8, 16, 24]]"
              :page="newPlugins.page"
              @update:page="newPluginsChangePage"
              @update:itemsPerPage="newPluginsChangePerPage"
            >
              <template v-slot:no-data v-if="!newPlugins.itemsLoading">
                <template v-if="newPlugins.searchTerm">
                  <data-iterator-no-results-container
                    @clearAllFilters="newPlugins.searchTerm = ''"
                    :searchTerm="newPlugins.searchTerm"
                  />
                </template>
              </template>
              <template v-slot:loading>
                <v-row class="mt-0">
                  <v-col
                    v-for="(_, key) in 8"
                    :key="key"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    xl="3"
                    class="responsive-card"
                  >
                    <plugin-card-skeleton />
                  </v-col>
                </v-row>
              </template>
              <template v-slot:grid="{ items: items }">
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  md="6"
                  xl="3"
                  v-for="(item, key) in items"
                  :key="key"
                >
                  <new-plugin-card
                    :item="item"
                    :loading="newPlugins.itemsLoading"
                    :itemIsFeatured="key === 0 ? true : false"
                    @action-button-details="showDetailsModal(item)"
                    @action-button-install="showInstallModal(item)"
                    @action-button-read-more="readMoreActive = $event"
                    @action-button-delete="showPluginDeleteModal($event)"
                  ></new-plugin-card>
                </v-col>
              </template>
            </data-iterator>
          </template>
          <!-- TABLE VIEW -->

          <template v-if="!$vuetify.breakpoint.mdAndDown && listStyle === 1">
            <new-plugin-table
              :checkedItems="[]"
              :headers="newPlugins.headers"
              :items="filteredNewPlugins"
              :itemsLoading="newPlugins.itemsLoading"
              :itemsPerPage="newPlugins.itemsPerPage"
              :itemsTotal="newPlugins.itemsTotal"
              :page="newPlugins.page"
              :possibleItemsPerPage="[[8, 16, 24]]"
              :searchTerm="newPlugins.searchTerm"
              @clearAllFilters="newPluginsHandleSearchTermChange('')"
              :sortBy.sync="newPlugins.sortBy"
              :sortDesc="newPlugins.sortDesc"
              @update:itemsPerPage="newPluginsChangePerPage"
              @update:page="newPluginsChangePage"
              @update:sortBy="newPluginsChangeSortBy"
              @update:sortDesc="newPluginsChangeSortDesc"
              @action-button-details="showDetailsModal"
              @action-button-install="showInstallModal($event)"
              @action-button-read-more="readMoreActive = $event"
            >
            </new-plugin-table>
          </template>
        </template>
      </v-tab-item>
    </v-tabs-items>
    <!-- PLUGINS -->

    <PluginReadMoreModal
      :item="readMoreActive"
      @resetModal="readMoreActive = {}"
      @installItem="showInstallModal"
      @action-button-install="showInstallModal($event)"
      @action-button-activate="showPluginActivateModal($event)"
      @action-button-deactivate="showPluginDeactivateModal($event)"
      @action-button-update="showPluginUpdateModal($event)"
      @action-button-delete="showPluginDeleteModal($event)"
    />

    <v-dialog
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
      transition="custom-dialog-transition"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
      >
        <template v-slot:custom="{ item }">
          <template v-if="item">
            <template v-if="Array.isArray(item)">
              <div class="target-item-box flex-column">
                <div
                  v-for="(subitem, index) in item"
                  :key="index"
                  class="d-flex"
                  :class="item.length - 1 !== index ? 'mb-3' : ''"
                >
                  <span
                    class="gray--text text--darken-1"
                    v-html="subitem.title || subitem.name"
                  ></span>

                  <span
                    class="ml-auto"
                    style="min-width: 48px"
                    v-if="subitem.rating"
                  >
                    <v-icon
                      size="20"
                      class="mr-1"
                      :percent="subitem.rating / 100"
                    >
                      $starIcon
                    </v-icon>
                    <b class="p-4">{{
                      Math.ceil((subitem.rating / 10) * 5) / 10
                    }}</b>
                  </span>
                </div>
              </div>
            </template>

            <div class="target-item-box" v-else-if="Object.keys(item).length">
              <span
                class="gray--text text--darken-1"
                v-html="item.title || item.name"
              ></span>
              <span class="ml-auto" style="min-width: 48px" v-if="item.rating">
                <v-icon size="20" class="mr-1" :percent="item.rating / 100">
                  $starIcon
                </v-icon>
                <b class="p-4">{{ Math.ceil((item.rating / 10) * 5) / 10 }}</b>
              </span>
            </div>
          </template>
        </template>
      </basic-modal>
    </v-dialog>
    <plugins-select-snackbar
      :items="items"
      :checkedItems="checkedItems"
      :checkedPlugins="getCheckedItems()"
      @update:check-all="handleSelectAll"
      @mass-action-remove="showPluginMassDeleteModal(getCheckedItems())"
      @mass-action-activate="showPluginMassActivateModal(getCheckedItems())"
      @mass-action-deactivate="showPluginMassDeactivateModal(getCheckedItems())"
      @mass-action-update="showPluginMassUpdateModal(getCheckedItems())"
    />
  </div>
</template>

<script>
import {
  fetchActiveInstancePlugins,
  fetchNewPlugins,
  fetchNewPluginsFromPackages,
} from "../../router/fetchData";

import DataIteratorPageMixin from "../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../mixins/CustomTablePageMixin";
import ActionModalMixin from "../../mixins/ActionModalMixin";
import PluginActionsMixin from "../../mixins/PluginsActionsMixin";

import PluginTable from "../../components/plugins/PluginTable.vue";
import NewPluginTable from "../../components/plugins/NewPluginTable.vue";
import ListGridSwitch from "../../components/buttons/ListGridSwitch.vue";
import DataIterator from "../../components/dataIterator/DataIterator.vue";
import PluginCard from "../../components/plugins/PluginCard.vue";
import NewPluginCard from "../../components/plugins/NewPluginCard.vue";
import DataIteratorFilterControls from "../../components/dataIterator/DataIteratorFilterControls.vue";
import PluginCardSkeleton from "../../components/plugins/PluginCardSkeleton.vue";
import PluginsSelectSnackbar from "../../components/plugins/PluginsSelectSnackbar.vue";
import BasicModal from "@/components/modal/BasicModal";
import TableFullScreenMessage from "../../components/dataIterator/tables/TableFullScreenMessage.vue";
import DataIteratorNoResultsContainer from "../../components/dataIterator/DataIteratorNoResultsContainer.vue";
import NewItemSearchCard from "../../components/dataIterator/NewItemSearchCard.vue";
import PluginReadMoreModal from "../../components/plugins/PluginReadMoreModal.vue";

export default {
  components: {
    PluginTable,
    NewPluginTable,
    ListGridSwitch,
    DataIterator,
    PluginCard,
    NewPluginCard,
    DataIteratorFilterControls,
    BasicModal,
    PluginCardSkeleton,
    PluginsSelectSnackbar,
    TableFullScreenMessage,
    DataIteratorNoResultsContainer,
    NewItemSearchCard,
    PluginReadMoreModal,
  },
  props: {
    instance: Object,
  },
  data() {
    return {
      readMoreActive: {},
      fetching: true,
      addPlugins: false,
      /*
       * installed plugins
       */
      headers: [
        {
          text: "",
          value: "select",
          sortable: false,
        },
        {
          text: this.$t('table.header.name'),
          align: "start",
          value: "title",
          sortable: true,
          searchable: true,
        },
        {
          text: this.$t('table.header.version'),
          value: "version",
          sortable: false,
        },
        {
          text: this.$t('table.header.status'),
          sortable: true,
          value: "status",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      sortDesc: false,
      sortBy: {
        text: this.$t('table.header.name'),
        align: "start",
        value: "title",
        sortable: true,
        searchable: true,
      },
      items: [],
      allItems: [],
      page: 1,
      itemsPerPage: 8,
      itemsLoading: true,
      searchTerm: "",
      itemsTotal: 0,

      /**
       * new plugins
       */
      newPlugins: {
        keys: [
          {
            text: this.$t('table.sort.noSort'),
            value: "",
            sortable: true,
          },
          {
            text: this.$t('table.sort.popular'),
            value: "active_installs",
            sortable: true,
          },
          {
            text: this.$t('table.sort.downloadable'),
            value: "active_installs",
            sortable: true,
          },
          {
            text: this.$t('table.sort.rated'),
            value: "rating",
            sortable: true,
          },
          {
            text: this.$t('table.sort.recent'),
            value: "last_updated",
            sortable: true,
          },
        ],
        headers: [
          {
            text: this.$t('table.header.name'),
            align: "start",
            value: "name",
            sortable: true,
          },
          {
            text: this.$t('table.header.rating'),
            value: "rating",
            sortable: true,
          },
          {
            text: this.$t('table.header.version'),
            value: "version",
            sortable: false,
          },
          {
            text: this.$t('table.header.installations'),
            value: "active_installs",
            sortable: true,
          },
          {
            text: this.$t('table.header.requires'),
            sortable: true,
            value: "tested",
          },
          {
            text: this.$t('table.header.lastUpdated'),
            value: "last_updated",
            sortable: true,
          },
          {
            text: "",
            value: "actions",
            sortable: false,
            align: "end",
          },
        ],
        sortDesc: false,
        sortBy: {
          text: this.$t('table.sort.noSort'),
          value: "",
          sortable: true,
        },
        items: [],
        allItems: [],
        page: 1,
        itemsPerPage: 8,
        itemsLoading: false,
        searchTerm: "",
        itemsTotal: 0,
      },
      /**
       * modal
       */
      modalRender: 0,
      modalOptions: {},

      applicationId: this.$route.params.id,
    };
  },
  mixins: [
    DataIteratorPageMixin,
    CustomTablePageMixin,
    PluginActionsMixin,
    ActionModalMixin,
  ],
  mounted() {
    this.$root.$on("plugin-item-changed", (plugin) => {
      this.instance.setPluginItem(plugin);
      this.updatePluginStatus(plugin);
    });
    this.loadData();

    this.reloadNewPlugins();
  },
  watch: {
    searchTerm: function () {
      this.$nextTick(() => {
        this.checkedItems = this.checkedItems.filter((item) => {
          return this.items.find((i) => i.name == item);
        });
      });
    },

    addPlugins: function (value) {
      if (value) {
        this.checkedItems = [];
        this.newPlugins.searchTerm = "";
        this.$nextTick(() => {
          this.reloadNewPlugins();
        });
      }
    },
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  methods: {
    getCheckedItems() {
      return this.items.filter((item) => {
        return this.checkedItems.includes(item.name);
      });
    },
    updatePluginStatus(plugin) {
      for (let [key, item] of this.items.entries()) {
        if (item.name == plugin.name) {
          this.items[key] = this.transformItem(plugin);
          this.items = [...this.items];
          break;
        }
      }
      for (let item of this.newPlugins.items) {
        if (item.slug == plugin.name || item.slug == plugin.slug) {
          item.async_status = plugin.async_status;
          this.newPlugins.items = [...this.newPlugins.items];
          break;
        }
      }
    },
    transformItem(item) {
      if (typeof item.async_status === "undefined") {
        item.async_status = {};
      }
      item.hasInstallInProgress = () => {
        return (
          item.async_status &&
          (item.async_status.install == "pending" ||
            item.async_status.install == "in_progress")
        );
      };
      item.hasUpdateInProgress = () => {
        return (
          item.async_status &&
          (item.async_status.update == "pending" ||
            item.async_status.update == "in_progress")
        );
      };
      return item;
    },
    setData(data) {
      this.allItems = data.map((item) => {
        return this.transformItem(item);
      });
      this.loadPlugins();
      this.$emit("plugins-reload", this.allItems);
    },
    loadData() {
      fetchActiveInstancePlugins(this.applicationId).then((response) => {
        this.itemsLoading = false;
        if (response.error) {
          // console.error("ERROR: ", response.error);
          return;
        }
        this.allItems = response.data.map((item) => {
          return this.transformItem(item);
        });
        this.fetching = false;
        this.loadPlugins();
        this.$emit("plugins-reload", this.allItems);
      });
    },
    loadPlugins() {
      this.itemsLoading = true;
      let items = [...this.allItems];
      // filtering
      if (this.searchTerm) {
        items = items.filter((item) => {
          for (const header of this.headers) {
            if (header.searchable) {
              if (
                item[header.value]
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase())
              ) {
                return true;
              }
            }
          }
          return false;
        });
      }
      this.itemsTotal = items.length;
      // sorting
      items = items.sort((i1, i2) => {
        if (!isNaN(i1[this.sortBy.value]) && !isNaN(i2[this.sortBy.value])) {
          return (
            (i1[this.sortBy.value] - i2[this.sortBy.value]) *
            (this.sortDesc ? -1 : 1)
          );
        }
        return (
          i1[this.sortBy.value]
            .toString()
            .localeCompare(i2[this.sortBy.value].toString()) *
          (this.sortDesc ? -1 : 1)
        );
      });
      // paging
      items = items.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage
      );
      this.items = items;
      this.itemsLoading = false;
    },
    changePage(page) {
      this.page = page;
      this.loadPlugins();
    },
    changePerPage(perPage) {
      this.page = 1;
      this.itemsPerPage = perPage;
      this.loadPlugins();
    },
    changeSortBy: function (data) {
      this.page = 1;
      this.sortBy = data;
      this.loadPlugins();
    },
    changeSortDesc: function (data) {
      this.page = 1;
      this.sortDesc = data;
      this.loadPlugins();
    },
    handleSearchTermChange: function (newValue) {
      this.page = 1;
      this.searchTerm = newValue;
      this.loadPlugins();
    },

    /**
     * new plugins
     */
    reloadNewPlugins() {
      this.newPlugins.itemsLoading = true;
      if (this.newPlugins.searchTerm) {
        fetchNewPlugins(this.applicationId, this.newPlugins.searchTerm).then(
          (response) => {
            this.newPlugins.itemsLoading = false;
            if (response.error) {
              // console.error("ERROR: ", response.error);
              return;
            }
            this.newPlugins.allItems = response.data.map((item) => {
              return this.transformItem(item);
            });
            this.loadNewPlugins();
          }
        );
      } else {
        fetchNewPluginsFromPackages(this.applicationId).then((response) => {
          this.newPlugins.itemsLoading = false;
          if (response.error) {
            // console.error("ERROR: ", response.error);
            return;
          }
          this.newPlugins.allItems = response.data.map((item) => {
            item.featured = true;
            return this.transformItem(item);
          });
          this.loadNewPlugins();
        });
      }
    },
    loadNewPlugins() {
      this.newPlugins.itemsLoading = true;
      let items = [...this.newPlugins.allItems];
      this.newPlugins.itemsTotal = items.length;

      // sorting

      var sortBy = this.newPlugins.sortBy.value;
      var sortDesc = this.newPlugins.sortDesc;

      items = items.sort((i1, i2) => {
        if (!isNaN(i1[sortBy]) && !isNaN(i2[sortBy])) {
          return (i1[sortBy] - i2[sortBy]) * (sortDesc ? -1 : 1);
        }
        // console.log({ i1, i2 });
        // return (
        //   i1[sortBy]
        //     .toString()
        //     .localeCompare(i2[sortBy].toString()) * (sortDesc ? -1 : 1)
        // );
      });
      // paging

      items = items.slice(
        (this.newPlugins.page - 1) * this.newPlugins.itemsPerPage,
        this.newPlugins.page * this.newPlugins.itemsPerPage
      );

      this.newPlugins.items = items;
      this.newPlugins.itemsLoading = false;
    },
    newPluginsChangePage(page) {
      this.newPlugins.page = page;
      this.loadNewPlugins();
    },
    newPluginsChangePerPage(perPage) {
      this.newPlugins.page = 1;
      this.newPlugins.itemsPerPage = perPage;
      this.loadNewPlugins();
    },
    newPluginsChangeSortBy: function (data) {
      this.newPlugins.page = 1;
      this.newPlugins.sortBy = data;
      this.loadNewPlugins();
    },
    newPluginsChangeSortDesc: function (data) {
      this.newPlugins.page = 1;
      this.newPlugins.sortDesc = data;
      this.loadNewPlugins();
    },
    newPluginsHandleSearchTermChange: function (newValue) {
      this.newPlugins.searchTerm = newValue;
      this.reloadNewPlugins();
    },

    /**
     * modal
     */
    showInstallModal(item) {
      this.showPluginInstallModal(item);
    },
    showDetailsModal(item) {
      this.showPluginInstallModal(item);
    },
  },
  computed: {
    filteredNewPlugins() {
      return this.newPlugins.allItems.filter((item) => {
        return !this.allItems.find((i) => i.name === item.slug);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-window::v-deep {
  overflow: visible;

  .v-window__container {
    overflow: visible !important;
  }
}

.target-item-box::v-deep {
  border-radius: 6px;
  border: 1px solid var(--v-gray-lighten3);
  display: flex;
  font-size: $font-size-base;
  line-height: $line-height-base;
  padding: 16px;
  background: var(--v-gray-lighten5);
  font-weight: $font-weight-semibold;
  margin-bottom: 8px;

  + form {
    margin-top: 16px;
  }
}
</style>
